
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import zh from "./locales/zh.json";

const resources = {
  en: {
    translation: en,
    home: en.homepage
  },
  'zh-TW': {
    translation: zh,
    home: zh.homepage
  },
};

const lang = localStorage.getItem('lang');

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  lng: lang ?? 'zh-TW',
  fallbackLng: 'zh-TW',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;