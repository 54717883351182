const ArtsList = [
  {
    name: 'Algorithmic Currents',
    creator: 'Cromwell',
    date: '2024/11/01-30',
  },{
    name: 'Calls from the deep',
    creator: 'Igor Yakunin',
    date: '2024/11/01-30',
  },{
    name: 'Gummy Sparks',
    creator: 'Pavel Druy',
    date: '2024/11/01-30',
  },
  {
    name: 'Digital Nature Dreams',
    creator: 'MILKORVA',
    date: '2024/10/01-31',
  },{
    name: 'Wilder Barbershop',
    creator: 'Wild Design',
    date: '2024/10/01-31',
  },{
    name: 'Palometa from Cartagen',
    creator: 'YuZapata',
    date: '2024/10/01-31',
  },{
    name: 'Adventures of The Toy Maker',
    creator: 'Amrit Pal Singh',
    date: '2024/09/01-30',
  },{
    name: 'Midnight Reflections',
    creator: 'Nokturnal',
    date: '2024/09/01-30',
  },{
    name: 'Where I\'m headed today',
    creator: 'Tahn',
    date: '2024/09/01-30',
  },
  {
    name: 'The Defier',
    creator: 'C-Lo',
    date: '2024/08/01-31',
    time: '每晚 9:45 pm – 10:00 pm'
  },{
    name: 'Disappearing Pollination',
    creator: 'YUNFUL',
    date: '2024/08/01-31',
    time: '每晚 9:45 pm – 10:00 pm'
  },{
    name: 'Illumination',
    creator: 'Zenavi',
    date: '2024/08/01-31',
    time: '每晚 9:45 pm – 10:00 pm'
  }];

export default ArtsList;