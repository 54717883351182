import { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player'
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import IconSocial from './assets/icon-1.png';
import IconContract from './assets/icon-2.png';
import IconPress from './assets/icon-3.png';
import IconMap from './assets/icon-4.png';
import LogoKyc from './assets/kaiyue_logo_white.png';
import LogoTP from './assets/TP_Logo_white.png';
import MapEn from './assets/map_en.png';
import MapZh from './assets/map_zh.png';
import TreeLogo from './assets/TAIPEI LUMITREE_LOGO.png';
import Artwork1 from './assets/artwork-1.jpg';
import Artwork2 from './assets/artwork-2.png';
import Artwork3 from './assets/artwork-3.png';
import Section3 from './assets/3-3.jpg'
import Section5 from './assets/5.png'
import ArtsList from './data/arts';

import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

const KYC_URL = "https://www.kycon.com.tw/";
const TP_URL = "https://thetigerparty.com/";
const IG_URL = "https://www.instagram.com/taipei_lumitree";
const GOOGLE_DOC_URL = "https://forms.gle/iSkfj8fXv6CCgJwj9";
const GOOGLE_DOC_URL_ZH  = "https://forms.gle/Cn1YXsMorUjUspLE6";


const Home = () => {
  const { t } = useTranslation(['home', 'translation']);
  const [currLng, setCurrLng] = useState(i18n.language);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [playVideo, setPlayVideo] = useState(false);
  const riseTitleRefs = useRef([]);
  riseTitleRefs.current = [];
  const videoRef = useRef();
  const section4Ref = useRef();
  const section5Ref = useRef();

  const importAll = (r) => {
    let images = [];
    r.keys().forEach((item) => { 
      images.push(r(item))
    });
    return images;
  }
  const ART_IMAGES = importAll(require.context('./assets/arts/', false, /\.(png|jpe?g|svg)$/))
  const ART_IMAGES_MOBILE = importAll(require.context('./assets/arts-mobile/', false, /\.(png|jpe?g|svg)$/))
  const HERO_IMAGES = importAll(require.context('./assets/hero/', false, /\.(png|jpe?g|svg)$/))

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('rise-title');
          observer.unobserve(entry.target);
        }
      });
    });

    riseTitleRefs.current.forEach(element => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      riseTitleRefs.current.forEach(element => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setPlayVideo(true);
          console.log("set play video")
          // entry.target.attributes('muted', 'true');
          // entry.target.play();
          observer.unobserve(entry.target);
        }
      });
    });
    if (videoRef.current)
      observer.observe(videoRef.current);

    return () => {
      if (videoRef.current)
        observer.unobserve(videoRef.current);
    };
  }, []);

  window.addEventListener("resize", () => {
    setIsMobile(window.innerWidth < 768);
  });

  const addToRefs = (el) => {
    if (el && !riseTitleRefs.current.includes(el)) {
      riseTitleRefs.current.push(el);
    }
  };

  const workList = [{
    image: Artwork1,
    name: 'LUMi 的日常',
    name_EN: 'Everyday with LUMi',
    creator: 'JL DESIGN',
  },{
    image: Artwork2,
    name: '台灣山林之聲',
    name_EN: 'Taiwan\'s Forest Symphony',
    creator: '程紀皓 & ANKR',
    creator_EN: 'Howard Cheng & ANKR',
  },{
    image: Artwork3,
    name: '台北色彩時鐘',
    name_EN: 'Gradient Clock ',
    creator: 'Tiger Party',
  }];

  const changeLng = (lng) => {
    setCurrLng(lng);
    i18n.changeLanguage(lng);
    localStorage.setItem('lang', lng);
  };

  useEffect(() => {
    let lng = i18n.language;
    if (lng === 'zh-TW') lng = 'zh';
    setCurrLng(lng);
  }, []);

  return (
    <div className={`${currLng === 'en' ? 'font-DMSans' : 'font-TaipeiSan'} text-lumitree-black `}>
      {/* Header */}
      <div className="flex flex-row items-center justify-between md:h-[7.5rem] h-20 md:mx-20 mx-4">
        <div className="h-auto w-2/5 md:w-[30%] flex items-center">
          <img className="" alt="Lumi Tree Logo" src={TreeLogo} />
        </div>
        <button 
          className="md:w-36 w-1/4 px-2 h-[2.5rem] border-logo border-2 text-logo font-bold md:text-body-2 text-[0.65rem]"
          onClick={() => changeLng(currLng === 'en' ? 'zh-TW' : 'en')}>
          {currLng === 'en' ? '中文' : 'ENGLISH'}
        </button>
      </div>

      <div className="flex md:items-center items-end  md:h-[625px] h-[350px] absolute z-20 w-full px-10">
        <div className="2xl:w-1/2 md:w-2/3 md:my-10 pb-10  items-start flex-col flex">
          <div ref={addToRefs} className="md:text-head-1 text-head-2 text-left text-white">{t('section_1.title')}</div>
          <button 
            className="md:mt-6 mt-16 md:py-2 md:px-8 py-1 px-4 text-body-1 bg-logo text-white font-bold"
            onClick={() => section4Ref.current.scrollIntoView({ behavior: 'smooth'})}>
            {`${t('section_1.button')}`}
          </button>
        </div>
      </div>

      <Swiper
        className='absolute'
        modules={[Autoplay]}
        spaceBetween={0}
        loop={true}
        slidesPerView={1}
        allowTouchMove={false}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {HERO_IMAGES.map((bg, index) => {
          return ( 
            <SwiperSlide className="w-full">
              <div
                style={{ backgroundImage: `url('${bg}')`}} 
                className={` md:h-[625px] h-[350px] w-full bg-norepeat bg-cover bg-center`}>
              </div>
            </SwiperSlide>)})}
      </Swiper>

      <div className="2xl:px-40 md:px-28 px-10 md:py-20 py-10 flex flex-col md:flex-row md:items-center md:h-[625px]">
        <div ref={addToRefs} className="my-10 md:w-1/2 text-left md:text-head-2 text-title-1 text-logo self-start">{t('section_2.title')}</div>
        <div className="leading-8	md:mt-14 mt-6 md:mx-6 md:w-1/2 text-left md:text-body-1 text-sm text-logo">{t('section_2.subhead')}</div>
      </div>

      <div className="2xl:px-40 md:px-28 px-10 md:py-20 py-10 flex flex-col md:flex-row items-center md:h-[625px] justify-center gap-10 md:gap-20">
        <div className="md:w-1/2 flex md:h-[100%] w-full h-auto md:max-w-[625px]">
            <img className="object-cover h-full w-full" src={Section3} alt=''/>
        </div>

        <div className="md:mt-0 mt-6 md:w-1/2 text-left flex flex-col max-w-[625px]">
          <div className="md:text-head-2 text-title-1 text-logo">{t('section_3.title')}</div>
          <div className="pt-10 text-body-1 leading-8	">{t('section_3.subhead')}</div>
        </div>
      </div>

      <div ref={videoRef} className="flex w-[100%] h-[auto] aspect-video max-w-[1080px] mx-auto">
        <ReactPlayer
          className="justify-center mx-auto"
          url={`${currLng === 'en' ? 'https://player.vimeo.com/video/1001933852?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' : 'https://player.vimeo.com/video/1010466137?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'}`}
          width="80%"
          height="auto"
          controls={true}
          playing={playVideo}
          muted={true}
          volume={0.5}
          playsinline={true}
        />
      </div>
      
      {workList.length > 0 && 
        <div ref={section4Ref} className="md:py-20 py-10 md:px-0 px-10 flex-row items-center h-auto">
          <div className="md:pl-16 my-8 text-left md:text-head-2 text-title-1 text-logo self-start">{t('section_4.title')}</div>
          <div className="md:pl-16 mb-2 md:w-2/3 text-left text-body-1 self-start">{t('section_4.subhead')}</div>
          <div className="mt-8 grid md:grid-cols-3  grid-cols-1 gap-6 md:mx-12">
            {workList.map((work, index) => {
              return ( 
                <div key={index} className='basis-1/3 flex flex-col justify-center p-4 border-[1px] border-lumitree-grey'>
                  <img src={work.image} alt="artwork" className="h-[250px] object-cover" />
                  <div className="mt-4 text-title-2 text-left text-logo">{currLng === 'en' ? work.name_EN ?? work.name : work.name}</div>
                  <div className="mt-2 text-body-2 text-left">{currLng === 'en' ? work.creator_EN ?? work.creator : work.creator}</div>
                </div>
              )
            })}
          </div>
        </div>
      }

      <div ref={section5Ref} className="2xl:px-40 md:px-28 px-10 md:py-20 py-10 flex flex-col-reverse md:flex-row justify-center md:h-[725px] gap-10">
        <div className="2xl:pr-40 md:w-2/5 md:my-0 my-10 text-left md:text-body-1 text-title-1 text-logo flex flex-col justify-between">
          <div>
            <div className="md:text-head-2 text-title-1 text-logo">{t('section_5.title')}</div>
            <div className="md:pt-6 md:text-title-2 text-title-2 text-logo">{t('section_5.subhead')}</div>
          </div>
          <div className="md:pt-20 pt-10 md:my-10 text-body-1 leading-[2.25rem] text-lumitree-black">{t('section_5.content')}</div>
          <div className="pb-10">
            <button className="text-body-2 py-2 px-8 text-logo border border-logo font-bold"
              onClick={()=> window.open(`${currLng === 'en' ? GOOGLE_DOC_URL : GOOGLE_DOC_URL_ZH}`,'_blank')}>{t('section_5.button')}</button>
          </div>
        </div>
        <div className="md:right-0 h-full max-w-[625px] w-3/5 flex md:h-[100%] w-full h-auto md:max-w-[625px]">
          <img className="object-cover h-full w-full" src={Section5} alt=''/>
        </div>
      </div>

      {ArtsList && <div className="pb-20 md:px-0 px-10 md:py-20 py-10 flex-row items-center h-auto">
        <div className="md:px-16 md:my-8 md:w-1/2 text-left md:text-head-2 text-title-1 text-logo self-start">{t('section_6.title')}</div>
        {/* <div dir="rtl" className="flex  h-20">
          <div className="w-10 invisible md:visible swiper-button-next">{'>'}</div>
          <div className="invisible md:visible swiper-button-prev">{'<'}</div>
        </div> */}
        <Swiper
          modules={[Navigation, Pagination]}
          pagination={{
            clickable: true,
            bulletClass: 'h-20 swiper-pagination-bullet'
          }}
          spaceBetween={50}
          slidesPerView={isMobile? 1 : 2.5}
          slidesOffsetBefore={isMobile ? 0 : 64}
          slidesOffsetAfter={isMobile ? 0 : 64}
        >
          {isMobile ? 
            ArtsList.reduce((acc, _, i) => (i % 4 ? acc : [...acc, ArtsList.slice(i, i + 4)]), []).map((artGroup, groupIndex) => {
              return (
                <SwiperSlide className='my-10 pb-10'>
                  <div class="grid grid-cols-2 gap-2 gap-y-6">
                    {artGroup.map((art, index) => {
                      return (
                      <div key={index} className='flex-row bg-white'>
                        <div className={`md:h-[377px]  h-[200px] bg-cover bg-center`} style={{ backgroundImage: `url('${ART_IMAGES_MOBILE[groupIndex * 4 + index]}')`}}></div>
                        <div className="my-4 text-title-2 text-left border-b-[1px] border-logo h-16 flex items-center">{art.name}</div>
                        <div className="mt-2 text-body-2 text-left">{art.creator}</div>
                        <div className="mt-2 text-body-2 text-left ">{t('section_6.exhibit')}{art.date}</div>
                      </div>)})}
                  </div>
                </SwiperSlide>
              )
            }) :
            ArtsList.map((art, index) => {
              return ( 
                <SwiperSlide className='my-10 pb-10'>
                  <div key={index} className='flex-row bg-white'>
                    <div className={`md:h-[377px]  h-[200px] bg-cover bg-center`} style={{ backgroundImage: `url('${ART_IMAGES[index]}')`}}></div>
                    <div className="my-4 text-title-2 text-left border-b-[1px] border-logo leading-[2.5rem]">{art.name}</div>
                    <div className="mt-2 text-body-1 text-left">{art.creator}</div>
                    <div className="mt-2 text-body-1 text-left ">{t('section_6.exhibit')}{art.date}</div>
                  </div>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </div>}

      <div className="md:px-16 px-10 md:py-20 py-10 flex md:flex-row flex-col items-center bg-[url('./assets/7.png')] md:h-[625px] h-[420px] bg-cover">
        <div ref={addToRefs} className="md:mr-16 mx-4 mb-20 md:w-1/2 md:text-right md:text-head-2 text-title-1 text-white self-start font-bold">{t('about_us.title')}</div>
        <div className="2xl:mx-40 md:mr-28 md:ml-10 mx-4 md:w-1/2 text-left self-end">
          <div className='pb-10 text-body-1 md:leading-8 text-white'>{t('about_us.content')}</div>
          <button
            className="md:mt-6 md:py-2 py-1 md:px-8 px-2 text-body-1 leading-8 bg-logo text-white font-bold" 
            onClick={()=> window.open(KYC_URL,'_blank')}
          >{t('about_us.button')}</button>
        </div>
      </div>

      <div className="md:px-10 px-10 md:py-20 py-10 flex md:flex-row-reverse flex-col-reverse">
        <div className="md:mx-12 md:w-1/2 md:pl-0 md:my-10 text-left flex flex-col justify-between">
          { !isMobile && 
            <div className="md:mb-32">
              <img className="w-[80%]" alt="Lumi Tree Logo" src={TreeLogo} />
            </div>
          }
          <div className="w-1/2 text-title-2 text-logo border-b-2 border-logo font-TaipeiBold">{t('section_8.subhead')}</div>
          <div className="md:mb-8 my-2 text-body-2 text-lumitree-black">{t('section_8.content')}</div>
          <div>
            <img className="md:w-6 w-4" alt={'Map Icon'} src={IconMap} />
            <div className="text-body-2 text-lumitree-black">{t('section_8.address')}</div>
          </div>
        </div>
        <div className="md:mx-12 md:w-1/2 my-10">
          <img className="h-auto" alt="Lumi Tree Map" src={currLng === 'en' ? MapEn : MapZh} />
        </div>
        { isMobile && 
            <div className="md:mb-16 mb-4">
              <img className="w-[80%]" alt="Lumi Tree Logo" src={TreeLogo} />
            </div>
        }
      </div>

      {/* Footer */}
      <div className='flex md:flex-row flex-col justify-between md:py-12 py-12 md:px-24 px-10 bg-black text-lumitree-white'>
        
        <div className='md:basis-1/3 flex flex-col text-left'>
          <div className='md:mb-8 mb-2 align-center border-b-[1px] border-logo text-body-1 font-bold'>{t('translation:footer.left.title')}</div>
          <div className=' align-center md:text-body-2 text-body-1'>{t('translation:footer.left.content')}</div>
          <div className='flex h-12 py-2 my-4 items-center'>
            <img className="h-[100%] w-auto mr-6" alt={'KYC Logo'} src={LogoKyc} onClick={()=> window.open(KYC_URL,'_blank')} />
            <img className="h-[70%] w-auto" alt={'TP Logo'} src={LogoTP} onClick={()=> window.open(TP_URL,'_blank')} />
          </div>
          {!isMobile && <div className='md:text-body-2 mt-12'>© 2024 TAIPEI LUMITREE All rights reserved. </div>}
        </div>

        <div className="md:basis-2/5 flex flex-col md:mt-0 mt-10 md:mr-36 text-left">
          <div className='md:mb-8 mb-2 align-left border-b-[1px] border-logo text-body-1 font-bold'>{t('translation:footer.right.title')}</div>
          <div className="flex flex-row items-center pb-2 md:text-body-2 text-body-1" onClick={()=> window.open(IG_URL,'_blank')}>
            <img className="h-8 mr-4" alt="Tiger Party Logo" src={IconSocial} />
            {isMobile ? 
              <div className='align-center'>{t('translation:footer.right.row_1_m')}</div>
             :
              <div className='align-center'>{t('translation:footer.right.row_1')}</div>
            }
          </div>
          <div className="flex flex-row items-center pb-2 md:text-body-2 text-body-1">
            <img className="h-8 mr-4" alt="Tiger Party Logo" src={IconContract} />
            {isMobile ? 
              <div className='align-center'>{t('translation:footer.right.row_2_m')}</div>
             :
              <div className='align-center'>{t('translation:footer.right.row_2')}</div>
            }
          </div>
          <div className="flex flex-row items-center md:text-body-2 text-body-1">
            <img className="h-8 mr-4" alt="Tiger Party Logo" src={IconPress} />
            {isMobile ? 
              <div className='align-center'>{t('translation:footer.right.row_3_m')}</div>
             :
              <div className='align-center'>{t('translation:footer.right.row_3')}</div>
            }
          </div>
        </div>
        {isMobile && <div className='mt-12 text-left text-body-2'>© 2024 TAIPEI LUMITREE All rights reserved. </div>}
      </div>
    </div>
  )
}

export default Home;
